export const werkstoffe = [
	{
		name: "Aluminium Al",
		dichte: 2.7,
		emodul: 70000,
		gmodul: 27000,
	},
	{
		name: "Benutzerdefiniert",
		dichte: 0,
		emodul: 0,
		gmodul: 0,
	},
	{
		name: "Bronze CuSn 6",
		dichte: 8.8,
		emodul: 90000,
		gmodul: 33000,
	},
	{
		name: "Grauguss GG",
		dichte: 7.25,
		emodul: 115000,
		gmodul: 44000,
	},
	{
		name: "Grauguss GGG 40",
		dichte: 7.25,
		emodul: 167000,
		gmodul: 65000,
	},
	{
		name: "Kupfer Cu",
		dichte: 8.93,
		emodul: 90000,
		gmodul: 33000,
	},
	{
		name: "Messing CuZn 37",
		dichte: 8.4,
		emodul: 90000,
		gmodul: 33000,
	},
	{
		name: "Nadelholz Fichte",
		dichte: 0.95,
		emodul: 13000,
		gmodul: 4000,
	},
	{
		name: "Polyamid PA 6",
		dichte: 1.1,
		emodul: 2000,
		gmodul: 750,
	},
	{
		name: "Stahl allgemein",
		dichte: 7.85,
		emodul: 210000,
		gmodul: 80000,
	},
	{
		name: "Stahl Nickelstahl 36% Ni",
		dichte: 7.85,
		emodul: 206000,
		gmodul: 80000,
	},
	{
		name: "Stahl Schnellarbeiterstahl SS",
		dichte: 7.85,
		emodul: 206000,
		gmodul: 80000,
	},
	{
		name: "Stahl unlegiert; niedrig legiert",
		dichte: 7.85,
		emodul: 210000,
		gmodul: 80000,
	},
	{
		name: "Stahl X 12 CrNi 18 8",
		dichte: 7.9,
		emodul: 210000,
		gmodul: 80000,
	},
];
